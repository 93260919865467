export const normalizeJackpot = (jackpots, locationId) => {
  const locationJackpots =
    jackpots.nodes.filter(
      (jackpot) => jackpot.jackpotMeta.location.id === locationId,
    ) || null;

  if (!locationJackpots || !locationJackpots.length) return;

  if (locationJackpots.length === 1)
    return locationJackpots[0].jackpotMeta.maxAmount;

  return locationJackpots.reduce((acc, curr) =>
    acc.jackpotMeta.maxAmount > curr.jackpotMeta.maxAmount ? acc : curr,
  );
};
