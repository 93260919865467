import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/molecules/SEO';
import Layout from '@layouts/Layout';
import HeroSection from '@components/organisms/HeroSection';
import { normalizeJackpot } from '@normalizers/normalizeJackpot';
import { normalizeBonuses } from '@normalizers/normalizeBonuses';
import SupportImageMobile from '@images/mobile_home_page_s2.webp';
import SupportImage from '@images/home_page_s2.webp';
import AboutUsImageMobile from '@images/mobile_home_page_s1-min.webp';
import useToggle from '@hooks/useToggle';
import HeroImage from '@images/home_page_hero.webp';
import HeroImageMobile from '@images/mobile_home_page_hero-min.webp';
import AboutUsImage from '@images/home_page_s1.webp';
import { contentSubscribeInputs } from '@helpers/constants';
import BasicJackpotSection from '@components/organisms/BasicJackpotSection';
import BonusSection from '@components/organisms/BonusSection';
import { transformObject } from '@helpers/transformObject';
import TopFixedSection from '@components/organisms/TopFixedSection';
import ImageDesktop from '@images/sub_form.png';
import ImageMobile from '@images/sub_form_mobile.png';
import HomeBasicSection from '@components/organisms/HomeBasicSection';
import SubscriptionForm from '@components/molecules/Forms/SubscriptionForm';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import { SiteContext } from '@store/Provider';
import GradientLine from '@components/molecules/GradientLine/GradientLine';

const HomePage = (props) => {
  const {
    data: {
      allWp: {
        nodes
      },
      wpPage: page,
      allWpJackpot,
      allWpBonus,
      wp: {
        siteSettings: {
          siteSettings: {
            jackpotsSectionCurrency,
            subFormErrorMessages,
            menuText,
            menuLogo,
            cookiesUsage,
            termsAndConditions,
            ...subForm
          },
        },
      },
    },
  } = props;
  const { seo, uri, language, translations, frontPageMeta } = page;
  const currentLanguage = language ? language.slug : 'bg';
  const { nearestLocation } = useContext(SiteContext);

  const subFormCheckboxes = transformObject(subForm.subFormCheckboxesLabels);

  const locationJackpot = normalizeJackpot(allWpJackpot, nearestLocation.id);
  const locationBonuses = normalizeBonuses(
    allWpBonus,
    nearestLocation.id,
  )?.slice(0, 4);
  const { toggled, handleToggleClick } = useToggle(false);

  const isMobile = useBreakpoint(breakpoints.sm);
  const isMiddle = useBreakpoint(breakpoints.md);

  return (
    <Layout
      translations={translations}
      currentLanguage={currentLanguage}
      sideMenuContent={frontPageMeta.fpiSideMenuSection.menuItems}
    >
      <SEO
        title={seo?.title}
        uri={uri}
        headJson={seo?.headJSON}
        translations={translations}
        imageToPreload={isMobile ? HeroImageMobile : HeroImage}
      />
      <TopFixedSection
        menuText={menuText}
        menuLogo={menuLogo}
        handleToggleClick={handleToggleClick}
        toggled={toggled}
        generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
      />
      {!isMiddle && !toggled && (
        <div className="pointer-events-none">
          <GradientLine
            variant="home-two"
            position="absolute z-50 left-[50%] right-[27%] top-[190vh] lg:left-[50%] lg:right-[36%]"
          />

          <GradientLine
            variant="home-three"
            position="absolute top-[255vh] right-[50%] left-[38%] lg:left-[37%] lg:right-[50%] xl:top-[248vh]"
          />

          <GradientLine
            variant="home-four"
            position="absolute z-[5] top-[327vh] left-[46%] right-[15%] xmd:right-[20%] lg:top-[350vh] xl:left-[42%] xl:right-[31.5%]"
          />
        </div>
      )}
      <div className="mb-60">
        <HeroSection
          dataId={1}
          content={frontPageMeta.fpiHeroSection}
          heroImage={HeroImage}
          heroImageMobile={HeroImageMobile}
          scrollTo="#about-us-section"
          leftContent={{
            ...cookiesUsage,
            icon: 'icon-cookie md:!text-[56px] ',
          }}
          rightContent={{
            ...termsAndConditions,
            icon: 'icon-adulthood md:!text-[56px] ',
          }}
          toggled={toggled}
          animateHero={props.location.state?.animate}
          Line={<GradientLine variant="home-one" />}
        />
      </div>
      <HomeBasicSection
        dataId={2}
        id="about-us-section"
        content={frontPageMeta.fpiAboutUsSection}
        desktopImage={AboutUsImage}
        mobileImage={AboutUsImageMobile}
        animatePageTransition
      />
      <div className="lg:mb-[200px]">
        <BasicJackpotSection
          jackpotsSectionCurrency={jackpotsSectionCurrency}
          isHomePage
          id="jackpot-section"
          locationJackpot={locationJackpot}
          locationLink={nearestLocation.url}
          content={frontPageMeta.fpiJackpotSection}
        />
      </div>
      <BonusSection
        dataId={3}
        id="bonus-section"
        content={frontPageMeta.fpiBonusesSection}
        locationBonuses={locationBonuses}
      />
      <HomeBasicSection
        dataId={4}
        id="support-section"
        content={frontPageMeta.fpiSupportSection}
        desktopImage={SupportImage}
        mobileImage={SupportImageMobile}
        animatePageTransition
      />
      <SubscriptionForm
        isContentSubscribe
        title={subForm.subFormTitle}
        description={subForm.subFormText}
        privacy={{
          text: subForm.subFormDisclaimer.text,
          link: subForm.subFormDisclaimer.link.url,
          linkText: subForm.subFormDisclaimer.link.title,
        }}
        buttonText={subForm.subFormButtonText}
        defaultChecked={subFormCheckboxes.keys}
        inputs={contentSubscribeInputs}
        subFormCheckboxes={subFormCheckboxes.array}
        imageDesktop={ImageDesktop}
        imageMobile={ImageMobile}
        errors={subFormErrorMessages}
        checkBoxType="checkbox"
      />
    </Layout>
  );
};

export default HomePage;

export const query = graphql`
  query HOME_PAGE($id: String!) {
    wpPage(id: { eq: $id }) {
      ...HomePageContent
    }
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    allWpJackpot {
      nodes {
        jackpotMeta {
          location {
            ... on WpLocation {
              id
            }
          }
          maxAmount
          minAmount
        }
      }
    }
    allWpBonus {
      nodes {
        bonusMeta {
          location {
            ... on WpLocation {
              id
              title
            }
          }
          description
          endDate
          imageType
          name
          startDate
        }
        databaseId
      }
    }
    wp {
      ...SubscriptionFormContent
      siteSettings {
        siteSettings {
          jackpotsSectionCurrency
          subFormErrorMessages {
            emptyField
            invalidField
            requiredField
          }
          menuText
          menuLogo {
            altText
            publicUrl
            mediaItemUrl
          }
        }
      }
      ...CookiesAndTermsContent
    }
  }
`;
