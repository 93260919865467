import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import HomeBasicSection from '@components/organisms/HomeBasicSection';
import CrystalCard from '@components/molecules/Cards/CrystalCard';
import styles from './BonusSection.module.scss';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import { useInView } from 'framer-motion';
import useToggle from '@hooks/useToggle';

const moveCardToRight = (cards, id) => {
  const movedCards = [...cards];
  const modifiedArr = [movedCards.find(obj => obj.id === id), ...movedCards.filter(obj => obj.id !== id)];

  return modifiedArr;
};

const BonusSection = ({ id, content, locationBonuses, dataId }) => {
  const isMobile = useBreakpoint(breakpoints.md);
  const ref = useRef(null);
  const isInViewForAnimation = useInView(ref, { once: true });
  const isInView = useInView(ref);
  const [cards, setCards] = useState([]);
  const { toggled, handleToggleClick } = useToggle(false);

  const onClickHandler = (index, id, event) => {
    const el = document.getElementById(id);

    if (index === 0) {
      handleToggleClick();
      return;
    }

    if (event) {
      el.style.zIndex = 999;

      setTimeout(() => {
        clearInterval();
        setCards(moveCardToRight(cards, id));
      }, 100);

      setTimeout(() => {
        clearInterval();
        el.style.removeProperty('z-index');
      }, 500);
    }
  };

  const handleSetCardsEffect = () => {
    if (cards.length === 0) setCards(locationBonuses);
  };

  useEffect(handleSetCardsEffect, [cards.length, locationBonuses]);

  return (
    <div className="md:flex page-section h-[900px]" id={id} data-id={dataId}>
      {isMobile && <HomeBasicSection content={content} secondary />}
      <div
        ref={ref}
        className={`${toggled ? styles.toggled : ''} ${styles.bonusesWrapper}`}
      >
        {cards.map((bonus, index) => {
          const onClick = (e) => { onClickHandler(index, bonus?.id, e); }
          
          return (
          <CrystalCard
            isCardHand
            onClick={onClick}
            index={index}
            id={bonus?.id}
            key={bonus?.id}
            image={{ type: bonus?.imageType }}
            startDate={bonus?.startDate}
            endDate={bonus?.endDate}
            title={bonus?.name}
            location={{ title: bonus?.location.title }}
            description={bonus?.description}
            isInView={isInView}
            customStyles={
              isInViewForAnimation && !toggled
                ? styles.onScreen
                : (isInViewForAnimation && toggled && styles.toggledOnScreen) ??
                  ''
            }
          />
        )
        })}
      </div>
      {!isMobile && <HomeBasicSection id={id} content={content} secondary />}
    </div>
  );
};

BonusSection.propTypes = {
  content: PropTypes.object,
  dataId: PropTypes.number,
  id: PropTypes.string,
  locationBonuses: PropTypes.array,
};

export default BonusSection;
