export const normalizeBonuses = (bonuses, locationId) =>
  bonuses.nodes
    .filter((bonus) => bonus.bonusMeta.location.id === locationId)
    .map(
      ({
        bonusMeta: {
          description,
          endDate,
          imageType,
          name,
          startDate,
          location,
        },
        databaseId,
      }) => ({
        description,
        endDate,
        imageType,
        name,
        startDate,
        location: { title: location.title },
        id: databaseId,
      }),
    );
