import React, { useEffect, useState, useRef } from 'react';
import Image from '@components/molecules/ImageGatsby';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import { useInView } from 'framer-motion';
import styles from './HomeBasicSection.module.scss';
import Button from '@components/atoms/Buttons/Button';
import PropTypes from 'prop-types';
import { useHandleHover } from '@hooks/useHandleHover';
import { useUpdateDimensions } from '@hooks/useUpdateDimensions';
import { navigate } from 'gatsby';
import { landscapeBreakpoint } from '@helpers/constants';

const HomeBasicSection = ({
  content,
  id,
  desktopImage,
  mobileImage,
  secondary,
  dataId,
  animatePageTransition,
}) => {
  const isSmall = useBreakpoint(breakpoints.sm);
  const isMiddle = useBreakpoint(breakpoints.md);
  const { isHovered, handleMouseOut, handleMouseOver } = useHandleHover();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { height } = useUpdateDimensions(ref);
  const isLandscapeDevice = height < landscapeBreakpoint;

  const [isNavigating, setIsNavigating] = useState(false);

  const onClickHandler = () => setIsNavigating(true);

  useEffect(() => {
    if (!isNavigating) return;
    setTimeout(() => {
      navigate(content.actionButton?.url, {
        state: {
          animate: animatePageTransition && !secondary,
        },
      });
    }, 700);
  }, [
    animatePageTransition,
    content.actionButton?.url,
    isNavigating,
    secondary,
  ]);

  return (
    <>
      <Image
        image={isSmall ? mobileImage : desktopImage}
        fill={!isLandscapeDevice}
        className={`transition-all duration-[900ms] ${
          isLandscapeDevice ? 'absolute' : ''
        } ${isNavigating ? styles.navigating : ''}`}
      />
      <div
        data-id={dataId}
        id={id}
        className={`${styles.wrapper} ${
          secondary
            ? styles.secondarySection
            : `${
                isLandscapeDevice
                  ? 'page-section text-[12px] pb-[100px] mt-[100px]'
                  : 'section'
              } ${styles.primarySection}`
        }`}
      >
        <div
          ref={ref}
          className={`${styles.content} ${secondary ? '' : 'z-50'} ${
            isInView && !isNavigating
              ? 'in-view'
              : secondary && !isMiddle
              ? 'out-of-view-secondary'
              : 'out-of-view'
          } transition-1000`}
        >
          <div className={styles.tagWrapper}>
            {!isSmall && <div className="tag-line" />}
            <p className="tag">{content.name}</p>
            {isSmall && <div className="tag-line" />}
          </div>
          <h2 className="h1">{content.title}</h2>
          <p
            className={`${styles.description} ${
              secondary ? 'max-w-[350px]' : ''
            }`}
          >
            {content.description}
          </p>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={onClickHandler}
              handleMouseOver={handleMouseOver}
              handleMouseOut={handleMouseOut}
            >
              {content.actionButton?.title}
              <i
                className={`icon-interface-arrows-right ${
                  isHovered ? 'right-10' : 'right-20'
                }`}
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

HomeBasicSection.propTypes = {
  animatePageTransition: PropTypes.bool,
  content: PropTypes.shape({
    actionButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    description: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
  }),
  dataId: PropTypes.number,
  desktopImage: PropTypes.string,
  id: PropTypes.string,
  mobileImage: PropTypes.string,
  secondary: PropTypes.bool,
};

export default HomeBasicSection;
